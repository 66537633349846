import { gql } from "@apollo/client";

export const QUERY_RESTRICTION_AND_CURB_ZONES = gql`
  query curbZonesAndRestrictions(
    $filterInputRestriction: SearchDisabledCurbZoneInput!
    $filterInputCurbZone: SearchCurbZoneInput!
  ) {
    disabledCurbZonesByBounding(filterInput: $filterInputRestriction) {
      id
      curbZoneId
      geoPoint2d
      editLine
      description
      decreeIds
      geometry
      timeSpans {
        startDate
        endDate
        daysOfWeek
        daysOfMonth
        months
        timeOfDayStart
        timeOfDayEnd
      }
      streetName
      streetNumberStart
      streetNumberEnd
      cityId
      type
      width
      calculatedSpaces
      length
    }
    curbZones(filterInput: $filterInputCurbZone) {
      calculatedArea
      calculatedSpaces
      cityId
      curbPolicies {
        id
        curbPolicyId
        name
        rules {
          rate {
            rate
            rateUnit
          }
        }
        color {
          dashed
          fillColor
          lineColor
        }
      }
      editLine
      externalId
      geoPoint2d
      geometry
      id
      curbZoneId
      length
      name
      note
      parkingAngle
      publishedDate
      startDate
      streetName
      streetNumberStart
      streetNumberEnd
      width
    }
  }
`;

export const QUERY_CREATE_RESTRICTION = gql`
  mutation createDisabledCurbZone($createDisabledCurbZoneInput: CreateDisabledCurbZoneInput!) {
    createDisabledCurbZone(createDisabledCurbZoneInput: $createDisabledCurbZoneInput) {
      timeSpans {
        startDate
        endDate
        daysOfWeek
        daysOfMonth
        months
        timeOfDayStart
        timeOfDayEnd
      }
      streetName
      streetNumberStart
      streetNumberEnd
      id
      geometry
      curbZoneId
      decreeIds
      description
      editLine
      geoPoint2d
      type
      cityId
      width
      calculatedSpaces
      length
    }
  }
`;

export const QUERY_UPDATE_RESTRICTION = gql`
  mutation updateDisabledCurbZone($updateDisabledCurbZoneInput: UpdateDisabledCurbZoneInput!) {
    updateDisabledCurbZone(updateDisabledCurbZoneInput: $updateDisabledCurbZoneInput) {
      timeSpans {
        startDate
        endDate
        daysOfWeek
        daysOfMonth
        months
        timeOfDayStart
        timeOfDayEnd
      }
      streetName
      streetNumberStart
      streetNumberEnd
      id
      geometry
      curbZoneId
      decreeIds
      description
      editLine
      geoPoint2d
      type
      cityId
      width
      calculatedSpaces
      length
    }
  }
`;

export const QUERY_UPDATE_RESTRICTIONS = gql`
  mutation BulkUpdateRestrictions($updateDisabledCurbZoneInput: [UpdateDisabledCurbZoneInput!]!) {
    bulkUpdateRestrictions(updateDisabledCurbZoneInput: $updateDisabledCurbZoneInput) {
      timeSpans {
        startDate
        endDate
        daysOfWeek
        daysOfMonth
        months
        timeOfDayStart
        timeOfDayEnd
      }
      streetName
      streetNumberStart
      streetNumberEnd
      id
      geometry
      curbZoneId
      decreeIds
      description
      editLine
      geoPoint2d
      type
      cityId
      width
      calculatedSpaces
    }
  }
`;

export const QUERY_REMOVE_RESTRICTION = gql`
  mutation deleteDisabledCurbZone($id: String!) {
    deleteDisabledCurbZone(id: $id) {
      id
      curbZoneId
    }
  }
`;

export const QUERY_REMOVE_RESTRICTIONS = gql`
  mutation BulkDeleteRestrictions($ids: [String!]!) {
    bulkDeleteRestrictions(ids: $ids)
  }
`;
