import { AppMode, AppModeType, DisabledModeType, Restriction } from "types";
import { DateRangeType, formatDate } from "../../helper/date-time";
import { DecreeListing, DisabledPartCurbZoneDetail, DisabledPartMapToolBox } from "./components";
import { MapStyle, SearchPlace, SelectMapType, SwitchModeView } from "../curb-zone/components";
import { ModeViewType, disabledStreetMode } from "constants/map.const";
import React, { useMemo, useState } from "react";

import { DateRangeSelector } from "components/form-control/DateRangeSelector";
import { DisabledCurbZoneByDecree } from "./components/disabled-curb-zone-by-decree";
import { DisabledCurbZoneParams } from "./modes/disable-parts/disabled-part.type";
import { DisabledCurbZoneTooltip } from "./components/disabled-curb-zone-tooltip";
import { DisabledPartModal } from "./modes/disable-parts/disabled-parts-modal";
import { Range } from "react-date-range";
import { SelectedMultipleCurbZoneDetail } from "./components/selected-multiple-curb-zone-detail";
import Split from "react-split";
import { isEmpty } from "lodash";
import { useRestriction } from "./hooks/use-restriction";
import { useSetQueryParams } from "../../hooks/use-set-query-params";
import { Feature, Polygon } from "geojson";
import { useDecree } from "./hooks/use-decree";
import { SelectedMultipleDisabledCurbZoneDetail } from "./components/selected-multiple-disabled-curb-zone-detail";
import { RestrictionMultiplePrompt } from "./components/restriction-multiple-prompt";
import { getQueryParams } from "helper/utils";

export const DisabledCurbZoneMap = () => {
  const [appMode, setAppMode] = useState<AppMode>(AppModeType.View);
  const params = getQueryParams<DisabledCurbZoneParams>();
  const [selectedDateRangeType, setSelectedDateRangeType] = useState<DateRangeType>();

  const [selectedDate, setSelectedDate] = useState<Range>({
    startDate: params.disabledStartDate ? new Date(params.disabledStartDate) : undefined,
    endDate: params.disabledEndDate ? new Date(params.disabledEndDate) : undefined,
  });

  const setQueryParams = useSetQueryParams();

  const handleChangeDate = (item: Range, type?: DateRangeType) => {
    setSelectedDate(item);
    if (type) {
      setSelectedDateRangeType(type);
    }
    setQueryParams(
      {
        disabledStartDate: item.startDate
          ? formatDate(item.startDate.toDateString(), { format: "MM-dd-yyyy" })
          : undefined,
        disabledEndDate: item.endDate ? formatDate(item.endDate.toDateString(), { format: "MM-dd-yyyy" }) : undefined,
      },
      { merge: true },
    );
  };

  const { setDecreeParams, decreeParams, decreeData, isLoading, refetchDecree } = useDecree();

  const {
    markerPosition,
    selectAddress,
    viewport,
    mapType,
    setMapType,
    modeView,
    handleDragEnd,
    handleSelectDisabledCurbZone,
    handleZooming,
    mapRef,
    onViewStateChange,
    setModeView,
    currentCity,
    showRestrictionDetail,
    handleRemoveRestriction,
    handleRemoveMultipleDisabledCurbZones,
    setOpenRestrictionModal,
    setOpenDisabledCurbZoneMultipleModal,
    openRestrictionModal,
    cancelEditDisabledPart,
    handleSaveRestriction,
    restrictionData,
    preZooming,
    selectedDecree,
    handleSelectDecree,
    wrapMapRef,
    disabledToolTipData,
    isSpaceBarPressed,
    showCurbZoneMultiDetail,
    showDisabledCurbZoneMultiDetail,
    restrictionDetail,
    cancelCreateMultiple,
    cancelEditMultiple,
    handleSaveMultiDisabledCurbZone,
    clickDescriptionRestriction,
    cancelEditDisabledStreet,
    handleSelectDisabledStreet,
    handleUpdateMultiDisabledCurbZone,
    openDisabledCurbZoneMultipleModal,
    mapLayers,
  } = useRestriction({ appMode, selectedDate, refetchDecree });

  const isShowRestrictionModal = useMemo(() => {
    return openRestrictionModal && restrictionData;
  }, [openRestrictionModal, restrictionData]);

  const cancelEdit = (isEdit: boolean) => {
    if (disabledStreetMode.includes(appMode)) {
      cancelEditDisabledStreet(isEdit);
    } else {
      cancelEditDisabledPart(isEdit);
    }
  };

  const handleSelectDisabledPart = (info: any) => {
    if (disabledStreetMode.includes(appMode)) {
      handleSelectDisabledStreet(info);
    } else {
      handleSelectDisabledCurbZone(info);
    }
  };

  const handleOpenDisabledModal = () => {
    setOpenRestrictionModal();
  };

  const handleSelectDescriptionCard = (input: Feature<Polygon, Restriction>) => {
    clickDescriptionRestriction(input);
  };

  return (
    <div className="h-full top-0 w-full absolute">
      <Split className="split z-50 h-full" direction="vertical" minSize={0} sizes={[50, 50]}>
        <div className="relative overflow-hidden">
          <div className="relative h-full" ref={wrapMapRef}>
            <div className="absolute top-0 left-6 z-10 flex">
              <SearchPlace onSelect={selectAddress} coordinates={viewport} />
              <SelectMapType mapType={mapType} onSelectMapType={(value) => setMapType(value)} className="ml-2" />
              <DateRangeSelector
                variant="secondary"
                onChangeValue={handleChangeDate}
                from={selectedDate.startDate}
                to={selectedDate.endDate}
                selectedValue={selectedDateRangeType}
                className="mt-16 ml-2"
              />
            </div>
            <div>
              <MapStyle
                markerPosition={markerPosition}
                mapType={mapType}
                modeView={modeView}
                layers={mapLayers}
                handleDragEnd={handleDragEnd}
                handleSelectLayer={handleSelectDisabledPart}
                handleZooming={(event) => handleZooming(event, preZooming.current)}
                mapRef={mapRef}
                onViewStateChange={onViewStateChange}
                viewport={viewport}
                appMode={appMode}
                isSpaceBarPressed={isSpaceBarPressed}
              />
            </div>
            {mapType === "Map box" && (
              <SwitchModeView modeView={modeView} selectModeView={(mode: ModeViewType) => setModeView(mode)} />
            )}
            {["multiEdit", "multiCreateDisabledCurbZone"].includes(appMode) && (
              <div className="absolute p-4 bottom-2 left-24 ml-6 bg-default rounded-md shadow-lg">
                <p>Press SPACE bar to move the map.</p>
                <p>Press SHIFT button to select one {appMode === AppModeType.MultiEdit ? "disabled" : ""} curb-zone.</p>
                <p>
                  Press ALT button to select multiple {appMode === AppModeType.MultiEdit ? "disabled" : ""} curb-zones.
                </p>
              </div>
            )}
            <div className="absolute top-36 left-8 z-6">
              <DisabledPartMapToolBox appMode={appMode} setAppMode={setAppMode} />
            </div>
            {(appMode === AppModeType.View ||
              appMode === DisabledModeType.EditDisabledCurbZone ||
              appMode === DisabledModeType.EditDisabledStreet) &&
              !isEmpty(selectedDecree) && (
                <DisabledCurbZoneByDecree
                  onSelectDescriptionCard={handleSelectDescriptionCard}
                  listDisabledCurbZones={selectedDecree?.disabledCurbZones || []}
                  selectedDecreeId={selectedDecree?.id}
                  selectedDescriptionCardId={restrictionDetail[0]?.id}
                />
              )}
          </div>
          {restrictionData
            ? showRestrictionDetail && (
                <DisabledPartCurbZoneDetail
                  id={restrictionData?.id as string}
                  disabledPart={restrictionData}
                  deleteDisabledPart={handleRemoveRestriction}
                  openParkingModal={handleOpenDisabledModal}
                  decreeList={decreeData.results}
                />
              )
            : null}
          {currentCity
            ? showCurbZoneMultiDetail && (
                <SelectedMultipleCurbZoneDetail
                  curbZones={restrictionDetail}
                  resetState={cancelCreateMultiple}
                  openCurbZoneMultiModal={setOpenRestrictionModal}
                />
              )
            : null}
          {currentCity
            ? showDisabledCurbZoneMultiDetail && (
                <SelectedMultipleDisabledCurbZoneDetail
                  disabledCurbZones={restrictionDetail}
                  resetState={cancelCreateMultiple}
                  openCurbZoneMultiModal={setOpenDisabledCurbZoneMultipleModal}
                  handleRemoveMultiDisabledCurbZone={handleRemoveMultipleDisabledCurbZones}
                />
              )
            : null}
        </div>
        <div className="overflow-y-auto">
          <DecreeListing
            decreeList={decreeData}
            selectedDecree={selectedDecree}
            onSelectDecree={handleSelectDecree}
            isLoading={isLoading}
            decreesParams={decreeParams}
            setDecreeParams={setDecreeParams}
          />
        </div>
        {disabledToolTipData && (
          <DisabledCurbZoneTooltip
            left={disabledToolTipData.x}
            top={disabledToolTipData.y}
            curbZoneHover={disabledToolTipData.curbZoneHover}
            disabledHover={disabledToolTipData.disabledHover}
            decreeList={decreeData.results}
          />
        )}
      </Split>
      {isShowRestrictionModal && restrictionData && appMode !== DisabledModeType.MultiCreateDisabledCurbZone && (
        <DisabledPartModal
          saveDisabledPart={handleSaveRestriction}
          prefill={restrictionData}
          cancel={cancelEdit}
          decreeList={decreeData.results}
        />
      )}
      {openRestrictionModal && restrictionData && appMode === DisabledModeType.MultiCreateDisabledCurbZone && (
        <RestrictionMultiplePrompt
          saveMultiRestriction={handleSaveMultiDisabledCurbZone}
          numberOfRestriction={restrictionDetail.length}
          cancel={cancelCreateMultiple}
          decreeList={decreeData.results}
        />
      )}
      {openDisabledCurbZoneMultipleModal && restrictionData && (
        <RestrictionMultiplePrompt
          saveMultiRestriction={handleUpdateMultiDisabledCurbZone}
          numberOfRestriction={restrictionDetail.length}
          cancel={cancelEditMultiple}
          decreeList={decreeData.results}
          isEdit
        />
      )}
    </div>
  );
};
