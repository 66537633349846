import { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { MacButton, Modal, ModalContent, ModalFooter } from "components";
import { TimeSpanListing } from "components/time-span";
import { DropdownMultiSelect, DropdownSelect, FieldContainer, FormInput } from "components/form-control";
import { Restriction } from "types";
import { DisabledPartProps } from "../modes/disable-parts/disabled-part.type";
import { restrictionType } from "../restriction";

type RestrictionMultiplePrompt = Pick<DisabledPartProps, "cancel" | "decreeList"> & {
  saveMultiRestriction: (promptedProperties: Restriction) => void;
  numberOfRestriction: number;
  isEdit?: boolean;
};

export const RestrictionMultiplePrompt = ({
  saveMultiRestriction,
  numberOfRestriction,
  cancel,
  decreeList,
  isEdit,
}: RestrictionMultiplePrompt) => {
  const [selectedDecree, setSelectedDecree] = useState<string[]>([]);

  const {
    setValue,
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Restriction>({
    mode: "onChange",
  });

  const listDecisionFile = useMemo((): any[] => {
    if (decreeList.length === 0) {
      return [];
    }
    return [...decreeList].reverse().map((item) => ({ value: item.id, label: item.name || "" }));
  }, [decreeList]);

  const dismiss = () => cancel(true);
  const onChangeFile = (fileName: string[]) => {
    setValue("decreeIds", fileName);
    setSelectedDecree(fileName);
  };

  const save = (promptedProperties: Restriction) => {
    saveMultiRestriction(promptedProperties);
  };

  return (
    <Modal isOpen className="my-0 h-full max-w-lg flex items-center content-center">
      <ModalContent
        className="pb-0 rounded-2xl"
        header={
          <>
            <h3 className="text-lg leading-6 font-medium text-gray-900">{isEdit ? "Edit" : "Create"} Restriction</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Currently selecting {numberOfRestriction} Restrictions
            </p>
          </>
        }
      >
        <form onSubmit={handleSubmit(save)}>
          <Controller
            name="timeSpans"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Timespan is required",
              },

              validate: (values) => {
                if (values) {
                  for (const item of values) {
                    if (!item.startDate || !item.endDate) {
                      return "Start date and end date must be filled";
                    }
                  }
                }
              },
            }}
            render={({ field: { value, onChange } }) => {
              return (
                <TimeSpanListing
                  errors={errors}
                  name="timeSpans"
                  timeSpans={value}
                  setTimeSpans={onChange}
                  options={{ showSelectDate: true, showTimeOfDay: true }}
                />
              );
            }}
          />
          <FieldContainer>
            <DropdownMultiSelect
              options={listDecisionFile}
              onChangeValues={onChangeFile}
              values={selectedDecree}
              className="w-36"
              placeholder="Decrees"
            />
          </FieldContainer>
          <FieldContainer
            helpText={errors.description ? "This is required" : undefined}
            status={errors?.description ? "error" : "primary"}
          >
            <FormInput
              label="Description"
              {...register("description", { required: true })}
              status={errors.description ? "error" : "primary"}
            />
          </FieldContainer>
          <FieldContainer>
            <DropdownSelect className="border-primary border-solid" options={restrictionType} {...register("type")} />
          </FieldContainer>
          <ModalFooter>
            <MacButton type="submit" variant="primary">
              Save
            </MacButton>
            <MacButton onClick={dismiss} variant="secondary">
              Cancel
            </MacButton>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
