import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { QUERY_LIST_DECREES, UPDATE_DECREE_FILE } from "services/web/api-decree.const";
import { DecreesResponse, PaginatedDecrees, PaginatedDecreeArgs, UpdateDecreeFileResponse } from "types/decree.type";

export const useQueryListDecreeFile = (input: PaginatedDecreeArgs) => {
  const {
    data = { paginatedDecrees: { results: [], totalPages: 0 } },
    loading,
    refetch: refetchDecree,
  } = useQuery<DecreesResponse>(QUERY_LIST_DECREES, {
    variables: {
      ...input,
      sortOrder: input.sortOrder?.toUpperCase(),
    },
    skip: !input.filter.find((item) => item.field === "cityId")?.value,
  });
  return {
    decreeData: data?.paginatedDecrees,
    isLoading: loading,
    refetchDecree,
  };
};

export const useUpdateDecree = (input: PaginatedDecreeArgs) => {
  const client = useApolloClient();

  const getCachedDecrees = (): PaginatedDecrees | null => {
    try {
      const cachedDecree = client.cache.readQuery({
        query: QUERY_LIST_DECREES,
        variables: {
          ...input,
          sortOrder: input.sortOrder?.toUpperCase(),
        },
      }) as DecreesResponse | null;

      return cachedDecree?.paginatedDecrees || null;
    } catch (error) {
      return null;
    }
  };

  const [updateDecreeFile] = useMutation<UpdateDecreeFileResponse>(UPDATE_DECREE_FILE, {
    onCompleted: (response) => {
      const decreesResponse = getCachedDecrees();

      if (!decreesResponse) {
        return;
      }

      const newDecreeListing = decreesResponse.results.map((item) => {
        if (item.id === response.updateDecreeFile.id) {
          return { ...item, ...response.updateDecreeFile };
        }
        return item;
      });

      client.cache.writeQuery({
        query: QUERY_LIST_DECREES,
        variables: {
          ...input,
          sortOrder: input.sortOrder?.toUpperCase(),
        },
        data: {
          paginatedDecrees: {
            results: newDecreeListing,
            totalPages: decreesResponse.totalPages,
          },
        },
      });
    },
    onError: (error) => {
      return error;
    },
  });

  return {
    updateDecreeFile,
  };
};
